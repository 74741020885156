import request from '@/utils/request'

/** 获取司机运行轨迹 */
export function getTrail(data) {
    return request({
        url: '/delivery/traffic/trail',
        method: 'POST',
        data: data
    })
}

/** 司机实时位置信息 */
export function getConstantly(data) {
    return request({
        url: '/delivery/traffic/driverConstantly',
        method: 'GET',
        params: data
    })
}

export function trailCount(data) {
    return request({
        url: '/delivery/traffic/trailCount',
        method: 'GET',
        params: data
    })
}

export function exportExceltrailCount(data) {
    return request({
        url: '/delivery/traffic/exportExceltrailCount',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}