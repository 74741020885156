<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warehouse" clearable>
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                               :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.driver" placeholder="请选择司机" clearable filterable>
                    <el-option v-for="(item, index) in drivers" :label="item.driverName" :value="item.guid" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        :editable="false"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        v-model="queryParams.localDate"
                        placeholder="请选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch">查询</el-button>
            </el-form-item>
        </el-form>
        <div class="monitor-content">
            <div class="map">
                <el-amap vid="GaodeMap" :center="center" class="bm-view" :zoom="zoom" :events="events" ref="GaodeMap">

                    <el-amap-bezier-curve v-if="data.length > 1" :path="data" strokeColor="rgb(64,158,255)" :showDir="true"
                                          :stroke-opacity="1" :stroke-weight="9" strokeStyle="solid"></el-amap-bezier-curve>
                    <el-amap-info-window
                            v-for="(marker, index) in driverTraffics" :key="index"
                            :position="marker.conter" :content="contentInit(marker)" class="window-info" :size="[360,140]" :visible="true">
                    </el-amap-info-window>

                    <el-amap-marker :position="item.map" v-for="(item, index) in markers" :key="index" :extData="item"
                                    :content="markerContent(item,index)" :offset="[-50,-58]" :events="markerEvents">
                    </el-amap-marker>

                    <el-amap-info-window
                            v-if="visible" :visible="visible" class="window-info" :position="driverItem.map" :events="windowEvents"
                            :size="[220,120]" :content="windowContent(driverItem)" :offset="[0, -30]">
                    </el-amap-info-window>

                </el-amap>
            </div>
        </div>
    </div>
</template>

<script>
    import {getAllDriver} from '@/api/system/driver'
    import {getTrail} from '@/api/delivery/traffic_trail'
    import {driverTrafficTime} from '@/api/delivery/traffic_road'
    import {getDelivery} from '@/api/delivery/delivery'

    export default {
        name: "Trail",
        data() {
            return {
                labelStyle_0: {color: '#FFF','border-color': 'green', fontSize : '16px','margin-top':'-60px', 'backgroundColor': 'green'},
                iconAddress:'http://cdn.boofoo.top/q_addr_1.png',
                warehouseOption: this.$store.getters.warehouse,
                queryParams: {
                    localDate: undefined,
                    warehouse: undefined,
                    driver: undefined
                },
                data: [],
                drivers: [],
                driverTraffics: [],
                center: [113.7956235322873, 22.92298117671754],
                zoom: 14,
                events: {
                    click : (e) => {
                        console.log(e)
                    }
                },
                markers:[],
                driverItem: {},
                visible: false,
                markerEvents: {
                    click : (e) => {
                        const exData = e.target.getExtData();
                        this.driverItem = exData
                        this.visible = true
                    },
                },
                windowEvents : {
                    close: () => {
                        this.visible = false
                    }
                }

            }
        },
        methods: {
            /** 查询 */
            querySearch() {
                this.data = [];
                this.driverTraffics = [];
                getTrail(this.queryParams).then(({code, data}) => {
                    if(code === 0){
                        this.data = data.map(item => {
                            return [Number(item.lng), Number(item.lat)]
                        })
                        this.center = this.data[0];
                    }
                })
                driverTrafficTime(this.queryParams).then(({code, data})=>{
                    if(code === 0){
                        this.driverTraffics = data.filter(item => item.map != null).map(item => {
                            item.isShow = true;
                            const split = item.map.split(",")
                            item.conter = [Number(split[0]),Number(split[1])]
                            return item;
                        });
                    }
                })
                getDelivery(this.queryParams).then(({code,data})=>{
                    if(code === 0){
                        this.markers=data.filter(item=>{
                            item.style = this.labelStyle_0;
                            item.icon = this.iconAddress;
                            const split = item.map.split(",")
                            item.map=[Number(split[0]), Number(split[1])]
                            return item;
                        })
                    }
                })
            },
            handler({BMap, map}) {

            },
            handlerClose(item){
                item.isShow = false
            },
            getClickInfo(eventName, handler, context) {
                console.log(eventName)
                console.log(handler)
                console.log(context)
            },
            markerContent(item,index){
                const style = item.style;
                return  `<div style="width:100px;text-align:center;">`+
                    `<p style="font-weight:bold;margin-bottom: 3px;font-size:12px;background-color:${style.backgroundColor};color:${style.color};">${index+1}:${item.companyName}</p>`+
                    `<img style="width:30px" src="${item.icon}" /></div>`;
            },
            windowContent(item){
                return `<div style="margin-top: 10px"><p><label>公司名称</label>：<span>${item.companyName}</span></p>`+
                    `<p><label>地址</label>：<span>${item.address}</span></p>`+
                    `<p><label>送货时间</label>：<span>${item.createTime}</span></p></div>`;
            },
            contentInit(item){
                const remark = item.remark ? `<p><label>备注</label>：<span>${item.remark}</span></p>`: ''
                return `<div style="margin-left: 10px;margin-top: 10px">`+
                    `<p><label>司机姓名</label>：<span>${item.driverName}</span></p>`+
                    `<p><label>联系电话</label>：<span>${item.driverTel}</span></p>`+
                    `<p><label>上报时间</label>：<span>${item.createTime}</span></p>`+
                    `<p><label>上报内容</label>：<span>${item.trafficContent}</span></p>`+
                    `<p><label>上报概述</label>：<span>${item.summary}</span></p>`+
                    remark +
                    `</div>`;
            }
        },
        watch: {
            'queryParams.warehouse': function (val, oldVal) {
                if(val) {
                    getAllDriver({warId: val}).then(({code, data}) => {
                        if (code === 0) {
                            this.drivers = data;
                        }
                    })
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
    .monitor-content {
        .map {
            padding: 10px;

            .bm-view {
                width: auto;
                height: 750px;

                .window-info {
                    p {
                        line-height: 1.5;
                    }

                    span {
                        color: rgb(144, 147, 153);
                        margin-left: 5px;
                    }

                    label {
                        text-align: justify;
                        text-align-last: justify;
                        display: inline-table;
                        color: rgb(81, 90, 110);
                        width: 80px;
                    }
                }

            }
        }
    }
</style>