import request from '@/utils/request'

// 分页获取司机信息
export function getList(data) {
    return request({
        url: '/system/driver/list',
        method: 'GET',
        params: data
    })
}

// 获取司机信息
export function getDriver(gid) {
    return request({
        url: '/system/driver/get/'+gid,
        method: 'GET'
    })
}

// 新增司机信息
export function saveDriver(data) {
    return request({
        url: '/system/driver/put',
        method: 'POST',
        data: data
    })
}

// 修改司机信息
export function updateDriver(data) {
    return request({
        url: '/system/driver/put',
        method: 'PUT',
        data: data
    })
}

// 删除司机
export function removeDriver(guid) {
    return request({
        url: '/system/driver/remove/'+guid,
        method: 'DELETE'
    })
}

// 禁用/启用 司机账户
export function disable(gid, status) {
    return request({
        url: '/system/driver/disable/'+gid +'/'+status,
        method: 'POST'
    })
}

// 重置密码
export function resetPwd(gid) {
    return request({
        url: '/system/driver/resetPwd/'+gid,
        method: 'POST'
    })
}

// 批量删除
export function deletes(ids) {
    return request({
        url: '/system/driver/removes',
        method: 'DELETE',
        data: ids
    })
}

/*
export function downLoad(param) {
    return request({
        url: '/system/driver/downLoadExcel',
        data: param,
        method: 'POST',
        responseType:'blob',
    })
}
*/

export function getAllDriver(warId) {
    return request({
        url: '/system/driver/all',
        method: 'GET',
        params: warId
    })
}


export function updateCensus(data) {
    return request({
        url: '/system/driver/census',
        method: 'POST',
        params: data
    })
}

export function exportExcel(data) {
    return request({
        url: '/system/driver/exportExcel',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}

export function getDriverInsure(data) {
    return request({
        url: '/system/driver/getDriverInsure',
        method: 'get',
        params: data
    })
}

export function getDriverVehicle(data) {
    return request({
        url: '/system/driver/getDriverVehicle',
        method: 'get',
        params: data
    })
}

export function exportExcelDriverVehicle(data) {
    return request({
        url: '/system/driver/exportExcelDriverVehicle',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}
export function exportExcelDriverImgCount(data) {
    return request({
        url: '/system/driver/exportExcelDriverImgCount',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}


export function getDriverImgCount(data) {
    return request({
        url: '/system/driver/getDriverImgCount',
        method: 'get',
        params: data
    })
}

export function getCompanyImgCount(data) {
    return request({
        url: '/system/driver/getCompanyImgCount',
        method: 'get',
        params: data
    })
}

export function writeOffDrive(data){
    return request({
        url: `/system/driver/writeOffDriver/${data}`,
        method: 'POST'
    })
}

export function exportExcelCompanyImgCount(data) {
    return request({
        url: '/system/driver/exportExcelCompanyImgCount',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}
export function exportExcelDriverInsure(data) {
    return request({
        url: '/system/driver/exportExcelDriverInsure',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}