import request from '@/utils/request'

/** 分页获取数据 */
export function getList(param) {
    return request({
        url: '/delivery/traffic/road/list',
        method: 'GET',
        params: param
    })
}

/** 删除一条数据 */
export function remove(guid) {
    return request({
        url: '/delivery/traffic/road/remove/' + guid,
        method: 'DELETE'
    })
}

/** 批量删除 */
export function deletes(ids) {
    return request({
        url: '/delivery/traffic/road/removes',
        method: 'DELETE',
        data: ids
    })
}

/** 去处理 */
export function processing(guid) {
    return request({
        url: '/delivery/traffic/road/processing/' + guid,
        method: 'POST'
    })
}

/** 处理完成 */
export function processed(data) {
    return request({
        url: '/delivery/traffic/road/processed',
        method: 'POST',
        data: data
    })
}

/** 获取司机某天上报的路况 */
export function driverTrafficTime(data) {
    return request({
        url: '/delivery/traffic/road/getDriverTrafficTime',
        method: 'POST',
        data: data
    })
}
